import React from "react";
import "./Terms.scss";
import { Container, Row, Col, Card, Button } from "react-bootstrap";

const TermsAndConditions = () => {
  return (
    <Container fluid className="pt-5 pb-5 terms-container">
      <Row className="justify-content-center">
        <Col md={10} lg={8}>
          <Card>
            <Card.Body className="card-container">
              <Card.Title className="text-center mb-4">
                Terms and Conditions
              </Card.Title>
              <Card.Text>
                <p>Welcome to Mentation Tech!</p>
                <p>
                  These terms and conditions outline the rules and regulations
                  for the use of Mentation Tech’s Website, located at{" "}
                  <a href="http://www.mentationtech.in">www.mentationtech.in</a>
                  .
                </p>
                <p>
                  By accessing this website, we assume you accept these terms
                  and conditions. Do not continue to use Mentation Tech if you
                  do not agree to take all of the terms and conditions stated on
                  this page.
                </p>

                <h5>Cookies</h5>
                <p>
                  We employ the use of cookies. By accessing Mentation Tech, you
                  agreed to use cookies in agreement with Mentation Tech’s
                  Privacy Policy.
                </p>
                <p>
                  Most interactive websites use cookies to let us retrieve the
                  user’s details for each visit. Cookies are used by our website
                  to enable the functionality of certain areas to make it easier
                  for people visiting our website. Some of our
                  affiliate/advertising partners may also use cookies.
                </p>

                <h5>License</h5>
                <p>
                  Unless otherwise stated, Mentation Tech and/or its licensors
                  own the intellectual property rights for all material on
                  Mentation Tech. All intellectual property rights are reserved.
                  You may access this from Mentation Tech for your own personal
                  use subjected to restrictions set in these terms and
                  conditions.
                </p>
                <p>You must not:</p>
                <ul>
                  <li>Republish material from Mentation Tech</li>
                  <li>
                    Sell, rent, or sub-license material from Mentation Tech
                  </li>
                  <li>
                    Reproduce, duplicate, or copy material from Mentation Tech
                  </li>
                  <li>Redistribute content from Mentation Tech</li>
                </ul>
                <p>
                  This Agreement shall begin on the date hereof. Our Terms and
                  Conditions were created with the help of the Terms And
                  Conditions Generator and the Privacy Policy Generator.
                </p>

                <h5>Comments</h5>
                <p>
                  Parts of this website offer an opportunity for users to post
                  and exchange opinions and information in certain areas of the
                  website. Mentation Tech does not filter, edit, publish, or
                  review Comments prior to their presence on the website.
                  Comments do not reflect the views and opinions of Mentation
                  Tech, its agents, and/or affiliates. Comments reflect the
                  views and opinions of the person who posts their views and
                  opinions.
                </p>

                <h5>Hyperlinking to our Content</h5>
                <p>
                  The following organizations may link to our Website without
                  prior written approval:
                </p>
                <ul>
                  <li>Government agencies</li>
                  <li>Search engines</li>
                  <li>News organizations</li>
                  <li>Online directory distributors</li>
                </ul>
                <p>
                  These organizations may link to our home page, publications,
                  or other Website information as long as the link: (a) is not
                  deceptive; (b) does not falsely imply sponsorship,
                  endorsement, or approval of the linking party and its products
                  and/or services; and (c) fits within the context of the
                  linking party’s site.
                </p>

                <h5>iFrames</h5>
                <p>
                  Without prior approval and written permission, you may not
                  create frames around our Webpages that alter in any way the
                  visual presentation or appearance of our Website.
                </p>

                <h5>Content Liability</h5>
                <p>
                  We shall not be held responsible for any content that appears
                  on your Website. You agree to protect and defend us against
                  all claims arising on your Website.
                </p>

                <h5>Your Privacy</h5>
                <p>Please read our Privacy Policy.</p>

                <h5>Reservation of Rights</h5>
                <p>
                  We reserve the right to request that you remove all links or
                  any particular link to our Website. You agree to immediately
                  remove all links to our Website upon request.
                </p>

                <h5>Removal of Links</h5>
                <p>
                  If you find any link on our Website that is offensive for any
                  reason, you are free to contact and inform us at any moment.
                  We will consider requests to remove links but are not
                  obligated to do so or to respond directly.
                </p>

                <h5>Disclaimer</h5>
                <p>
                  To the maximum extent permitted by applicable law, we exclude
                  all representations, warranties, and conditions relating to
                  our website and the use of this website. Nothing in this
                  disclaimer will:
                </p>
                <ul>
                  <li>
                    Limit or exclude our or your liability for death or personal
                    injury;
                  </li>
                  <li>
                    Limit or exclude our or your liability for fraud or
                    fraudulent misrepresentation;
                  </li>
                  <li>
                    Limit any of our or your liabilities in any way that is not
                    permitted under applicable law;
                  </li>
                  <li>
                    Exclude any of our or your liabilities that may not be
                    excluded under applicable law.
                  </li>
                </ul>
                <p>
                  The limitations and prohibitions of liability set in this
                  Section and elsewhere in this disclaimer: (a) are subject to
                  the preceding paragraph; and (b) govern all liabilities
                  arising under the disclaimer, including liabilities arising in
                  contract, in tort, and for breach of statutory duty.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsAndConditions;
