import React from "react";
import "./Fleet.scss";
import { Card, Col, Container, Row } from "react-bootstrap";
import fintech from "../../../../data.json";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Fleet() {
  const fintechData = fintech.FleetManagement;
  console.log(fintechData);

  return (
    <>
      <Helmet>
        <title>Fleet Management Solutions | Mentation Technologies</title>
        <meta
          name="description"
          content="Enhance your fleet operations with Mentation Technologies' advanced fleet management solutions. Our services include real-time tracking, route optimization, and fuel management to boost efficiency and cut costs."
        />
        <meta
          name="keywords"
          content="fleet management, vehicle tracking, route optimization, fuel management, Mentation Technologies, GPS solutions, fleet efficiency"
        />
        <meta
          property="og:title"
          content="Advanced Fleet Management Solutions | Mentation Technologies"
        />
        <meta
          property="og:description"
          content="Enhance your fleet operations with Mentation Technologies' advanced fleet management solutions. Our services include real-time tracking, route optimization, and fuel management to boost efficiency and cut costs."
        />
        <meta
          property="og:image"
          content="https://mentationtech.in/static/media/Logo.a8779e5090cd14a6ca38.png"
        />
        <meta
          property="og:url"
          content="https://mentationtech.in/products/FleetManagement"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Advanced Fleet Management Solutions | Mentation Technologies"
        />
        <meta
          name="twitter:description"
          content="Enhance your fleet operations with Mentation Technologies' advanced fleet management solutions. Our services include real-time tracking, route optimization, and fuel management to boost efficiency and cut costs."
        />
        <meta
          name="twitter:image"
          content="https://mentationtech.in/static/media/Logo.a8779e5090cd14a6ca38.png"
        />
      </Helmet>

      <div className="fleet-container">
        <div className="banner-container">
          <div className="banner">
            <div className="overlay"></div>
            <div className="text-content">
              <h1 className="heading-text">
                Optimize Your Fleet with Advanced Management Solutions
              </h1>
              <p className="paragraph-text">
                Optimize Your Fleet with Advanced Solutions" provides real-time
                tracking and route optimization to boost efficiency and cut
                costs.
              </p>
            </div>
          </div>
        </div>

        <div className="second-container">
          <Container>
            <div className="right">
              <div className="title">
                <h1>Fleet Management</h1>
              </div>
              <div className="description">
                <p className="desc">
                  Fleet management solutions are systems or platforms that help
                  businesses and organizations manage their fleets of vehicles
                  more effectively. These solutions typically provide tools and
                  features to track, maintain, and optimize fleet operations,
                  which can include cars, trucks, ships, planes, or any other
                  vehicles used for business purposes. Few key features of a
                  typical fleet management solution include (1) GPS Tracking and
                  Telematics (2) Maintenance Management (3) Fuel Management and
                  (4) Behavioral monitoring. The features if utilized to
                  creditable levels then it helps in Cost savings, Safety
                  implementations, increased productivity and regulatory
                  compliance.
                </p>
              </div>
            </div>
          </Container>
        </div>

        <Container>
          <div className="content-container">
            <div className="content-1">
              <div className="title">
                <h1>Mentation and Soloutions</h1>
              </div>
              <div className="des">
                Mentation’s Strategic Growth in Fleet Management - As an
                established leader in AIS140-compliant GPS devices, Mentation
                has naturally expanded into the fleet management market, where
                quality GPS devices are critical for success. Our entry into
                this competitive space has been supported by a state-of-the-art
                software development team and our sister company, Mentation
                Solutions (URL), which specializes exclusively in cutting-edge
                software solutions.
              </div>
            </div>
            <div className="content-2">
              <div className="title">
                <h1></h1>
              </div>
              <div className="des">
                With these strengths combined, Mentation has emerged as a key
                player in providing comprehensive fleet management services to
                established institutions, including schools, government
                organizations, and public sector undertakings (PSUs). Our
                integrated approach of high-quality GPS hardware and advanced
                software solutions ensures that our clients receive dependable,
                scalable, and efficient fleet management capabilities
              </div>
            </div>
          </div>
        </Container>

        <div className="third-container  my-5">
          <div className="products-section">
            <h1 className="title mt-5">
              "Presenting Our Finest Explore{" "}
              <span>"Mentation Technologies"</span> Groundbreaking Products!"
            </h1>
            <Container>
              <Row className="g-4 card-gallery">
                {fintechData.map((product) => (
                  <Col xs={12} sm={12} md={6} lg={4} key={product.id}>
                    <Card className="card">
                      <div className="image-container">
                        <Card.Img
                          className="image"
                          variant="top"
                          src={product.productImage}
                        />
                      </div>
                      <Card.Body className="card-body">
                        <Card.Title className="card-title">
                          {product.productName}
                        </Card.Title>
                        <Card.Text className="card-text d-inline-block truncate-multiline">
                          {product.productDescription}
                        </Card.Text>
                      </Card.Body>
                      <div className="button my-2">
                        <Link className="Link" to={product.baseUrl}>
                          Know More
                        </Link>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
