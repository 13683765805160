import React, { useState } from "react";
import "../../styles/Product.scss";
import locate from "../../../../assests/product/Loocate/Locate.png";
import mt580Dpdatasheet from "../../../../assests/product/Loocate/datasheet.jpeg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function P800() {
  const [mainImage, setMainImage] = useState(locate);

  const images = [locate];

  const handleThumbnailClick = (img) => {
    setMainImage(img);
  };

  return (
    <>
      <Helmet>
        <title>
          Mentation Locate GPS Tracker | Superior Vehicle Tracking & Fleet
          Management
        </title>
        <meta
          name="description"
          content="Mentation Locate is a state of the art GPS tracker designed for superior vehicle tracking and fleet management. It offers real-time location data, smart engine control, and is built to withstand varying conditions."
        />
        <meta
          name="keywords"
          content="Mentation Locate, GPS tracker, vehicle tracking, fleet management, real-time location, smart engine control, waterproof GPS"
        />
        <meta property="og:title" content="Mentation Locate GPS Tracker" />
        <meta
          property="og:description"
          content="Discover the Mentation Locate GPS tracker, designed for advanced vehicle tracking and fleet management with real-time updates, smart engine control, and durability in various conditions."
        />
        <meta property="og:image" url="https://mentationtech.in/static/media/Locate.46252e2fe2babf32f94e.png" />
        <meta property="og:url" content="https://mentationtech.in/products/locate/Locate" />
      </Helmet>

      <div>
        <div className="single-product">
          <section className="py-5">
            <div className="container">
              <div className="row gx-5">
                <aside className="col-lg-6 aside">
                  <div className="image-container">
                    <img
                      className="rounded-4 fit images"
                      src={mainImage}
                      alt="Main view"
                    />
                  </div>
                  <div className="d-flex justify-content-center mb-3">
                    {images.map((img, index) => (
                      <Link
                        key={index}
                        className="border mx-1 rounded-2 item-thumb"
                        onClick={() => handleThumbnailClick(img)}
                        to="#"
                      >
                        <img
                          width="60"
                          height="60"
                          className="rounded-2 thumbnail"
                          src={img}
                          alt={`Thumbnail ${index + 1}`}
                        />
                      </Link>
                    ))}
                  </div>
                </aside>
                <main className="col-lg-6 main">
                  <div className="ps-lg-3">
                    <h4 className="title text-dark">
                      Mentation Locate is a state of the art GPS tracker
                      tailored for superior vehicle tracking
                    </h4>
                    <div className="d-flex flex-row my-3 product-name">
                      <p>Locate</p>
                    </div>

                    <div className="description">
                      <p>
                        Mentation Locate is a state-of-the-art GPS tracker
                        tailored for superior vehicle tracking and fleet
                        oversight. It provides precise, real-time location data
                        to enhance vehicle monitoring and optimize
                        transportation efficiency
                      </p>
                    </div>

                    <div className="key-features">
                      <h1>Key features typically include</h1>
                      <div className="list">
                        <ul className="features">
                          <li>
                            <strong>High printing speed:</strong> <br />{" "}
                            Efficiently produces receipts and other documents.
                          </li>
                          <li>
                            <strong>Compact and lightweight design:</strong>{" "}
                            <br /> Ideal for portability.
                          </li>
                          <li>
                            <strong>Smart engine on/off:</strong> <br /> Allows
                            efficient control of device functionality.
                          </li>
                          <li>
                            <strong>Humidity 0~95%:</strong> <br /> Operates
                            reliably in varying humidity levels.
                          </li>
                          <li>
                            <strong>Waterproof:</strong> <br /> Designed to
                            withstand exposure to water.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="row short-heighlight">
                      <p className="des">Get To know More about this Product</p>
                      <span>
                        <Link
                          to={mt580Dpdatasheet}
                          target="_blank"
                          download="Locate"
                          className="link"
                        >
                          Download PDF
                        </Link>
                      </span>
                    </div>
                    <hr />
                  </div>
                </main>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
