import React from "react";
import "./Voter.scss";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import voter from "../../../../data.json";
import { Helmet } from "react-helmet";
// import dataSheet from "../../../../assests/Datasheet/MT580P Data Sheet.jpg";

export default function VoterManagement() {
  //startes
  const fintechData = voter.voter;

  return (
    <>
      <Helmet>
        <title>Voter Management Solutions - Mentation Technologies</title>
        <meta
          name="description"
          content="Explore Mentation Technologies' voter management solutions, including the MT580 Mini receipt printer designed for election days. Discover our hardware and software solutions for election integrity."
        />
        <meta
          name="keywords"
          content="voter management, election technology, MT580 Mini, receipt printer, thermal printers, GPS, fleet management, software solutions"
        />
        <meta
          property="og:title"
          content="Voter Management Solutions - Mentation Technologies"
        />
        <meta
          property="og:description"
          content="Explore Mentation Technologies' voter management solutions, including the MT580 Mini receipt printer designed for election days. Discover our hardware and software solutions for election integrity."
        />
        <meta
          property="og:image"
          content="https://mentationtech.in/static/media/Logo.a8779e5090cd14a6ca38.png"
        />
        <meta
          property="og:url"
          content="https://mentationtech.in/products/Voter"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Voter Management Solutions - Mentation Technologies"
        />
        <meta
          name="twitter:description"
          content="Explore Mentation Technologies' voter management solutions, including the MT580 Mini receipt printer designed for election days. Discover our hardware and software solutions for election integrity."
        />
        <meta name="twitter:image" content="https://mentationtech.in/static/media/Logo.a8779e5090cd14a6ca38.png" />
      </Helmet>

      <div className="voter-container">
        <div className="banner-container">
          <div className="banner">
            <div className="overlay"></div>
            <div className="text-content">
              <h1 className="heading-text">
                Leading Election Integrity with Advanced Technology
              </h1>
              <p className="paragraph-text">
                Leading election integrity with cutting-edge technology.
                Ensuring trust and transparency in every vote
              </p>
            </div>
          </div>
        </div>

        <div className="second-container">
          <Container>
            <div className="right">
              <div className="title">
                <h1>Complete Voter Management Platform</h1>
              </div>
              <div className="description">
                <p className="desc">
                  Mentation Technologies offers the Complete Voter Management
                  Platform, a robust solution designed to streamline voter
                  registration and tracking. Our platform ensures accuracy,
                  efficiency, and security for all your election needs.
                </p>
              </div>
            </div>
          </Container>
        </div>
        {/* Accordian Colntaienr */}
        <Container>
          <div className="content-container">
            <div className="content-1">
              <div className="title">
                <h1>Electoral System in India </h1>
              </div>
              <div className="des">
                We all know the democratic structure of India and this leads to
                voting process throughout the year for different governing
                bodies and authorities namely Panchayat, Municipal Corporations,
                State Assembly and Member of Parliament. The electoral process
                helps Election Management companies to associate with candidates
                to reach out to voters through offline and online campaigns.
              </div>
            </div>
            <div className="content-2">
              <div className="title">
                <h1>Mentation and the Voting Management Solution</h1>
              </div>
              <div className="des">
                In the process of reaching out to voters, leaders and their
                local representatives personally travel and meet individual
                voters and their family members and share details of their
                voting booth, date time and also eligible people in their
                families. This is where Mentation portable Bluetooth printers
                have made a significant impact in last decade. There are
                customized software’s with authentic database and while printing
                any information or voting slip our printer is widely used
              </div>
            </div>
          </div>
        </Container>

        <div className="third-container  my-5">
          <div className="products-section">
            <h1 className="title mt-5">
              "Presenting Our Finest Explore{" "}
              <span>"Mentation Technologies"</span> Groundbreaking Fintech
              Products!"
            </h1>
            <Container>
              <Row className="g-4 card-gallery">
                {fintechData.map((product) => (
                  <Col xs={12} sm={12} md={6} lg={4} key={product.id}>
                    <Card className="card">
                      <div className="image-container">
                        <Card.Img
                          className="image"
                          variant="top"
                          src={product.productImage}
                        />
                      </div>
                      <Card.Body className="card-body">
                        <Card.Title className="card-title">
                          {product.productName}
                        </Card.Title>
                        <Card.Text className="card-text d-inline-block truncate-multiline">
                          {product.productDescription}
                        </Card.Text>
                      </Card.Body>
                      <div className="button my-2">
                        <Link className="Link" to={product.baseUrl}>
                          Know More
                        </Link>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
