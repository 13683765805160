import React from "react";
import "./Management.scss";
import { Helmet } from "react-helmet";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FaXTwitter, FaLinkedinIn } from "react-icons/fa6";
import nitinProfilePic from "../../../assests/management/Nitin1.jpg";
// import anandProfilePic from "../../../assests/management/anand1.jpeg";
import anandProfilePic from "../../../assests/management/anand1.png";
import { Link } from "react-router-dom";

export default function Management() {
  return (
    <>
      {/* raect helmet */}
      <Helmet>
        <title> Our Management | Mentation Technologies</title>
        <meta
          name="description"
          content="Meet the leadership team at Mentation Technologies. Discover the expertise of our Co-Founder & CEO Nitin Navneet and Co-Founder & CMO Anand Murari, and learn about their contributions to hardware and software solutions in thermal printers, GPS, fleet management, and more."
        />
        <meta
          property="og:title"
          content="Meet Our Management Team | Mentation Technologies"
        />
        <meta
          property="og:description"
          content="Meet the leadership team at Mentation Technologies. Discover the expertise of our Co-Founder & CEO Nitin Navneet and Co-Founder & CMO Anand Murari, and learn about their contributions to hardware and software solutions in thermal printers, GPS, fleet management, and more."
        />
        <meta property="og:image" content="URL_TO_IMAGE" />
        <meta property="og:url" content="https://mentationtech.in/management" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Meet Our Management Team | Mentation Technologies"
        />
        <meta
          name="twitter:description"
          content="Meet the leadership team at Mentation Technologies. Discover the expertise of our Co-Founder & CEO Nitin Navneet and Co-Founder & CMO Anand Murari, and learn about their contributions to hardware and software solutions in thermal printers, GPS, fleet management, and more."
        />
        <meta name="twitter:image" content="URL_TO_IMAGE" />
      </Helmet>
      {/* raect helmet */}
      <div className="management-container">
        <div className="banner">
          <div className="overlay">
            <Container>
              <Row className="banner-content">
                <Col xs={12} md={8} lg={6}>
                  <h1 className="banner-title">
                    Steering Vision and Excellence In Every Endeavor
                  </h1>
                  <p className="banner-description">
                    At Mentation Management, we navigate the path to success
                    with precision, guiding vision and excellence in every
                    endeavor. Let us chart your course towards unparalleled
                    achievements
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <Container className="mt-2 management-team-heading">
          <h2 className="text-start">Our Management</h2>
          <p className="text-start">
            Meet the leadership team driving our company forward.
          </p>
        </Container>

        <Container className="my-5">
          <Row>
            <Col xs={12} md={7} lg={7} xl={8} className="management-data bg1">
              <div className="">
                <h1 className="management-title">
                  Nitin Navneet{" "}
                  <span className="management-subtitle">Co Founder & CEO</span>
                </h1>
                <p className="management-text">
                  Nitin Navneet boasts over two decades of experience in sales
                  and technology within the IT services industry. He has played
                  a pivotal role at Mentation, leading efforts to create
                  award-winning products and services across various market
                  segments, including banking, imaging, mobile technologies,
                  education, and financial inclusion.
                </p>
                <p className="management-text">
                  Nitin's professional stint with companies specializing in
                  RFID, biometrics, and printing solutions has been instrumental
                  in maintaining Mentation's competitive edge in product quality
                  and manufacturing processes. His extensive career includes
                  significant associations with multiple technology companies,
                  where he has leveraged his expertise to drive innovation and
                  growth. As a co-founder of several organizations in the
                  technology sector, he has demonstrated a strong
                  entrepreneurial spirit and a commitment to advancing the
                  industry
                </p>
                <div className="management-social-icons">
                  <div className="twitter">
                    <Link to="https://x.com/nitinnavneet" target="_blank">
                      <FaXTwitter className="management-icons" />
                    </Link>
                  </div>
                  <div className="linkedin">
                    <Link
                      to="https://www.linkedin.com/in/nitin-navneet-23206b10/"
                      target="_blank"
                    >
                      <FaLinkedinIn className="management-icons" />
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={2} lg={4} xl={2} style={{ height: "100%" }}>
              <Card className="management-card mt-5">
                <Card.Img
                  src={nitinProfilePic}
                  alt="management team member"
                  className="management-img"
                />
              </Card>
            </Col>
          </Row>
        </Container>

        <Container className="my-5">
          <Row>
            <Col xs={12} md={7} lg={7} xl={8} className="management-data bg3 ">
              <div className="">
                <h1 className="management-title">
                  Anand Murari{" "}
                  <span className="management-subtitle">Co Founder & CMO</span>
                </h1>
                {/* <p className="management-subtitle"></p> */}
                <p className="management-text">
                  Anand Murari brings nearly two decades of experience across
                  Banking, Financial Services, Insurance, Franchisee expansions,
                  and IT sales. Throughout his career, Anand has been
                  consistently recognized and awarded for his exceptional team
                  management, innovative sales initiatives, and outstanding
                  relationship management
                </p>
                <p className="management-text">
                  Anand's unique blend of aggressiveness and innovation has
                  enabled him to establish new channels and revitalize
                  underperforming products. His ability to navigate and leverage
                  market dynamics, especially over the past decade, has allowed
                  him to continuously explore and capitalize on emerging market
                  opportunities
                </p>
                <div className="management-social-icons">
                  <div className="twitter">
                    <Link
                      to="https://x.com/Mentationgroup?t=VsLYCkrKShNSNAq7JNsiTA&s=09"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaXTwitter className="management-icons" />
                    </Link>
                  </div>
                  <div className="linkedin">
                    <Link
                      to="https://www.linkedin.com/in/anand-murari-298a2917?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedinIn className="management-icons" />
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={2} lg={4} xl={2}>
              <Card className="management-card mt-5">
                <Card.Img
                  variant="top"
                  src={anandProfilePic}
                  alt="management team member"
                  className="management-img"
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
