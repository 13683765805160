import React from "react";
import "./Vehicle.scss";
import { Card, Col, Container, Row } from "react-bootstrap";
import fintech from "../../../../data.json";
import { Link } from "react-router-dom";

export default function Vehicle() {
  const fintechData = fintech.fintech;

  return (
    <>
      <div className="vehicle-container">
        <div className="vehicle-banner-container">
          <div className="vehicle-banner">
            <div className="overlay"></div>
            <div className="text-content">
              <h1 className="heading-text">
                Stay Connected Real-Time Vehicle Location at Your Fingertips
              </h1>
              <p className="paragraph-text">
                Experience unmatched peace of mind with our state-of-the-art
                vehicle location tracking device, designed for precision and
                reliability. Stay connected with your vehicle in real-time,
                anywhere, with seamless integration and user-friendly features
              </p>
            </div>
          </div>
        </div>

        <div className="second-container">
          <Container>
            <div className="right">
              <div className="title">
                <h1>AIS140 VLTD Device</h1>
              </div>
              <div className="description">
                <p className="desc">
                  Transportation in India is a vast and diverse network that
                  connects the country’s urban and rural areas. The system
                  includes roadways, railways, airways, and waterways. Roads are
                  the most widely used, ranging from national highways to
                  village roads. To ensure safety, security and quality
                  governance in public transport system at the optimum level,
                  Government of India has introduced AIS140 Vehicle Location
                  Tracking Device ( VLTD ) which is a GPS tracker that is
                  mandated for installation in certain commercial vehicles.
                </p>
              </div>
            </div>
          </Container>
        </div>

        <Container>
          <div className="content-container">
            <div className="content-1">
              <div className="title">
                <h1>The need of mandate for VLTD</h1>
              </div>
              <div className="des">
                One of the key objectives behind the mandatory use of AIS 140
                GPS devices is to ensure the safety of passengers. Real-time
                tracking allows authorities to monitor the movement of vehicles,
                facilitating a quick response in case of emergencies or
                accidents.
              </div>
            </div>
            <div className="content-2">
              <div className="title">
                <h1>Mentation and VLTD</h1>
              </div>
              <div className="des">
                The need of the hour was to introduce a world class tracking
                device with user friendly tracking software from individuals to
                corporates and institutions. The brand legacy was taken to next
                level with introduction of MTS140 which is ARAI certified AIS140
                VLTD device
              </div>
            </div>
          </div>
        </Container>

        <div className="third-container  my-5">
          <div className="products-section">
            <h1 className="title mt-5">
              "Presenting Our Finest Explore{" "}
              <span>"Mentation Technologies"</span> Groundbreaking Fintech
              Products!"
            </h1>
            <Container>
              <Row className="g-4 card-gallery">
                {fintechData.map((product) => (
                  <Col xs={12} sm={12} md={6} lg={4} key={product.id}>
                    <Card className="card">
                      <div className="image-container">
                        <Card.Img
                          className="image"
                          variant="top"
                          src={product.productImage}
                        />
                      </div>
                      <Card.Body className="card-body">
                        <Card.Title className="card-title">
                          {product.productName}
                        </Card.Title>
                        <Card.Text className="card-text d-inline-block truncate-multiline">
                          {product.productDescription}
                        </Card.Text>
                      </Card.Body>
                      <div className="button my-2">
                        <Link className="Link" to={product.baseUrl}>
                          Know More
                        </Link>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
