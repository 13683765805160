import React, { useState } from "react";
import "../../styles/Product.scss";
import mt580dp3 from "../../../../assests/product/Ais140/Mts140/MTS2.png";
import mt580dp4 from "../../../../assests/product/Ais140/Mts140/MTS1.png";
import mt580dp1 from "../../../../assests/product/Ais140/Mts140/MTS3.png";
import mt580Dpdatasheet from "../../../../assests/product/Ais140/Mts140/Datasheet.jpeg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function P800() {
  const [mainImage, setMainImage] = useState(mt580dp1);

  const images = [mt580dp1, mt580dp4, mt580dp3];

  const handleThumbnailClick = (img) => {
    setMainImage(img);
  };

  return (
    <>
      <Helmet>
        <title>
          Mentation MTS140 GPS Device | Advanced Vehicle Tracking and Fleet
          Management
        </title>
        <meta
          name="description"
          content="The Mentation MTS140 is an advanced GPS device designed for precise vehicle tracking and fleet management. Features include real-time location updates, compact design, E SIM, and remote cut-off."
        />
        <meta
          name="keywords"
          content="Mentation MTS140, GPS device, vehicle tracking, fleet management, real-time location, E SIM, remote cut-off"
        />
        <meta property="og:title" content="Mentation MTS140 GPS Device" />
        <meta
          property="og:description"
          content="Discover the Mentation MTS140 GPS device, designed for advanced vehicle tracking and fleet management. It offers real-time location updates, a compact design, E SIM connectivity, and remote cut-off capabilities."
        />
        <meta property="og:image" url="https://mentationtech.in/static/media/MTS3.b4358b2c7e89f65a270e.png" />
        <meta property="og:url" content="https://mentationtech.in/products/ais140/AIS140" />
      </Helmet>
      <div>
        <div className="single-product">
          <section className="py-5">
            <div className="container">
              <div className="row gx-5">
                <aside className="col-lg-6 aside">
                  <div className="image-container">
                    <img
                      className="rounded-4 fit images"
                      src={mainImage}
                      alt="Main view"
                    />
                  </div>
                  <div className="d-flex justify-content-center mb-3">
                    {images.map((img, index) => (
                      <Link
                        key={index}
                        className="border mx-1 rounded-2 item-thumb"
                        onClick={() => handleThumbnailClick(img)}
                        to="#"
                      >
                        <img
                          width="60"
                          height="60"
                          className="rounded-2 thumbnail"
                          src={img}
                          alt={`Thumbnail ${index + 1}`}
                        />
                      </Link>
                    ))}
                  </div>
                </aside>
                <main className="col-lg-6 main">
                  <div className="ps-lg-3">
                    <h4 className="title text-dark">
                      The Mentation MTS140 is a cutting-edge GPS device designed
                      for advanced vehicle tracking and fleet management
                    </h4>
                    <div className="d-flex flex-row my-3 product-name">
                      <p>MTS140</p>
                    </div>

                    <div className="description">
                      <p>
                        The Mentation MTS140 is a cutting-edge GPS device
                        designed for advanced vehicle tracking and fleet
                        management. It offers real-time location updates,
                        ensuring accurate monitoring of vehicles for transport
                        solutions
                      </p>
                    </div>

                    <div className="key-features">
                      <h1>Key features typically include</h1>
                      <div className="list">
                        <ul className="features">
                          <li>
                            <strong>Compact and lightweight design:</strong>{" "}
                            <br /> Ideal for portability.
                          </li>
                          <li>
                            <strong>E SIM:</strong> <br /> For seamless
                            connectivity without physical SIM cards.
                          </li>
                          <li>
                            <strong>Remote cut-off:</strong> <br /> Enables
                            remote disconnection when needed.
                          </li>
                          <li>
                            <strong>64 MB Flash:</strong> <br /> Ample storage
                            for data and applications.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="row short-heighlight">
                      <p className="des">Get To know More about this Product</p>
                      <span>
                        <Link
                          to={mt580Dpdatasheet}
                          target="_blank"
                          download="MTS140"
                          className="link"
                        >
                          Download PDF
                        </Link>
                      </span>
                    </div>
                    <hr />
                  </div>
                </main>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
