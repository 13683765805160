import React from "react";
import "./Ais140.scss";
import { Card, Col, Container, Row } from "react-bootstrap";
import fintech from "../../../../data.json";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Ais140() {
  const fintechData = fintech.Ais140;

  return (
    <>
      <Helmet>
        <title>
          MTS140 & Locate | Mentation
          Technologies
        </title>
        <meta
          name="description"
          content="Discover Mentation Technologies' advanced GPS tracking solutions, including the AIS140 and Locate devices for superior vehicle tracking and fleet management. Our solutions offer real-time location updates and enhanced transportation efficiency."
        />
        <meta
          name="keywords"
          content="GPS tracking, AIS140, Mentation Technologies, vehicle tracking, fleet management, real-time location, tracking solutions"
        />
        <meta
          property="og:title"
          content="Advanced GPS Tracking Solutions - AIS140 & Locate | Mentation Technologies"
        />
        <meta
          property="og:description"
          content="Discover Mentation Technologies' advanced GPS tracking solutions, including the AIS140 and Locate devices for superior vehicle tracking and fleet management. Our solutions offer real-time location updates and enhanced transportation efficiency."
        />
        <meta property="og:image" content="https://mentationtech.in/static/media/Logo.a8779e5090cd14a6ca38.png" />
        <meta property="og:url" content="https://mentationtech.in/products/AIS140" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Advanced GPS Tracking Solutions - AIS140 & Locate | Mentation Technologies"
        />
        <meta
          name="twitter:description"
          content="Discover Mentation Technologies' advanced GPS tracking solutions, including the AIS140 and Locate devices for superior vehicle tracking and fleet management. Our solutions offer real-time location updates and enhanced transportation efficiency."
        />
        <meta name="twitter:image" content="https://mentationtech.in/static/media/Logo.a8779e5090cd14a6ca38.png" />
      </Helmet>

      <div className="Ais140-container">
        <div className="banner-container">
          <div className="banner">
            <div className="overlay"></div>
            <div className="text-content">
              <h1 className="heading-text">
                Stay on Track with Innovative GPS Technology
              </h1>
              <p className="paragraph-text">
                Stay on Track with Innovative GPS Technology" delivers precise
                navigation with cutting-edge, real-time tracking solutions. Our
                advanced GPS systems ensure accurate and reliable guidance,
                keeping you confidently on course.
              </p>
            </div>
          </div>
        </div>

        <div className="second-container">
          <Container>
            <div className="right">
              <div className="title">
                <h1>AIS140 VLTD Device</h1>
              </div>
              <div className="description">
                <p className="desc">
                  Transportation in India is a vast and diverse network that
                  connects the country’s urban and rural areas. The system
                  includes roadways, railways, airways, and waterways. Roads are
                  the most widely used, ranging from national highways to
                  village roads. To ensure safety, security and quality
                  governance in public transport system at the optimum level,
                  Government of India has introduced AIS140 Vehicle Location
                  Tracking Device ( VLTD ) which is a GPS tracker that is
                  mandated for installation in certain commercial vehicles.
                </p>
              </div>
            </div>
          </Container>
        </div>

        <Container>
          <div className="content-container">
            <div className="content-1">
              <div className="title">
                <h1>The need of mandate for VLTD</h1>
              </div>
              <div className="des">
                One of the key objectives behind the mandatory use of AIS 140
                GPS devices is to ensure the safety of passengers. Real-time
                tracking allows authorities to monitor the movement of vehicles,
                facilitating a quick response in case of emergencies or
                accidents.
              </div>
            </div>
            <div className="content-2">
              <div className="title">
                <h1>Mentation and VLTD</h1>
              </div>
              <div className="des">
                The need of the hour was to introduce a world class tracking
                device with user friendly tracking software from individuals to
                corporates and institutions. The brand legacy was taken to next
                level with introduction of MTS140 which is ARAI certified AIS140
                VLTD device
              </div>
            </div>
          </div>
        </Container>

        <div className="third-container  my-5">
          <div className="products-section">
            <h1 className="title mt-5">
              "Presenting Our Finest Explore{" "}
              <span>"Mentation Technologies"</span> Groundbreaking Fintech
              Products!"
            </h1>
            <Container>
              <Row className="g-4 card-gallery">
                {fintechData.map((product) => (
                  <Col xs={12} sm={12} md={6} lg={4} key={product.id}>
                    <Card className="card">
                      <div className="image-container">
                        <Card.Img
                          className="image"
                          variant="top"
                          src={product.productImage}
                        />
                      </div>
                      <Card.Body className="card-body">
                        <Card.Title className="card-title">
                          {product.productName}
                        </Card.Title>
                        <Card.Text className="card-text d-inline-block truncate-multiline">
                          {product.productDescription}
                        </Card.Text>
                      </Card.Body>
                      <div className="button my-2">
                        <Link className="Link" to={product.baseUrl}>
                          Know More
                        </Link>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
