import React from "react";
import "./Billing.scss";
import { Card, Col, Container, Row } from "react-bootstrap";
import BillingandPOS from "../../../../data.json";
// import dataSheet from "../../../../assests/Datasheet/MT580P Data Sheet.jpg";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

export default function Billing() {
  //startes
  const fintechData = BillingandPOS.BillingandPOS;
  return (
    <>
      <Helmet>
        <title>Billing and POS Solutions | Mentation Technologies</title>
        <meta
          name="description"
          content="Explore Mentation Technologies' top-rated billing and POS solutions including MT800P, MT800DP, and MT800P2. Ideal for retail, service, and ticketing sectors in India."
        />
        <meta
          name="keywords"
          content="billing solutions, POS systems, thermal printers, Bluetooth printers, 80mm thermal printers, retail POS, service POS, Mentation Technologies"
        />
        <meta
          property="og:title"
          content="Mentation Technologies - Billing and POS Solutions"
        />
        <meta
          property="og:description"
          content="Discover the best billing and POS solutions from Mentation Technologies, including MT800P, MT800DP, and MT800P2. Perfect for various industries including retail and service sectors in India."
        />
        <meta
          property="og:image"
          content="https://mentationtech.in/static/media/Logo.a8779e5090cd14a6ca38.png"
        />{" "}
        {/* Replace with actual URL to the page's main image */}
        <meta
          property="og:url"
          content="https://mentationtech.in/products/Billing"
        />{" "}
        {/* Replace with actual URL */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Mentation Technologies - Billing and POS Solutions"
        />
        <meta
          name="twitter:description"
          content="Discover top billing and POS solutions by Mentation Technologies, including MT800P, MT800DP, and MT800P2. Perfect for various industries."
        />
        <meta
          name="twitter:image"
          content="https://mentationtech.in/static/media/Logo.a8779e5090cd14a6ca38.png"
        />{" "}
        {/* Replace with actual URL to the image */}
      </Helmet>

      <div className="billing-container">
        <div className="banner-container">
          <div className="banner">
            <div className="overlay"></div>
            <div className="text-content">
              <h1 className="heading-text">
                Streamline sales with our all in one billing and POS solutions
              </h1>
              <p className="paragraph-text">
                Revolutionize your business with our intuitive billing and POS
                solutions. Simplify transactions effortlessly
              </p>
            </div>
          </div>
        </div>

        <div className="second-container">
          <Container>
            <div className="right">
              <div className="title">
                <h1>Billing and Point of Sale (POS) Solutions in India</h1>
              </div>
              <div className="description">
                <p className="desc">
                  In India, the retail and service sectors are rapidly evolving,
                  leading to a growing demand for efficient billing and Point of
                  Sale (POS) solutions. These systems are crucial for businesses
                  of all sizes, from small retail shops to large chain stores,
                  as they streamline operations, enhance customer service, and
                  ensure compliance with tax regulations. The billing and POS
                  solution landscape in India is dynamic and continuously
                  evolving. Businesses are increasingly adopting advanced
                  systems to enhance efficiency, improve customer experiences,
                  and ensure compliance with regulatory requirements. As
                  technology continues to evolve, these solutions are expected
                  to become even more sophisticated, with enhanced features such
                  as AI-driven analytics and more robust security measures.
                </p>
              </div>
            </div>
          </Container>
        </div>
        {/* Accordian Colntaienr */}
        <Container>
          <div className="content-container">
            <div className="content-1">
              <div className="title">
                <h1>Mentation and the Solution</h1>
              </div>
              <div className="des">
                Billing systems in India have transitioned from manual processes
                to sophisticated digital platforms. Modern billing solutions
                offer features like automated invoice generation, inventory
                management, GST compliance, and multi-payment options. These
                systems cater to various sectors, including retail, hospitality,
                healthcare, PSU’s like electricity department and more. Wherein
                POS systems in India are designed to manage sales transactions
                efficiently. They typically include hardware (such as barcode
                scanners, receipt printers, and card swipers) and software that
                handles sales data, customer management, inventory tracking, and
                analytics. For both the solution receipt generation is
                compulsory for both regulatory reasons and customer satisfaction
                and this is where printers of MENTATION have been widely
                accepted and appreciated
              </div>
            </div>
            {/* <div className="content-2">
                <div className="title">
                  <h1>Mentation and FINTECH</h1>
                </div>
                <div className="des">
                  One of the key aspect of the overall financial technology is
                  that it is mandatory to have transaction record for all the
                  payments made or transfers received. This is where the leading
                  fintech players have made the maximum use of Mentation
                  Bluetooth thermal printers. We are more than proud to be
                  associated with industry stalwarts like Airtel Payments Bank,
                  Fino Payments Bank, Religare Broking, Relipay, Ezeepay,
                  Paynearby, Spice Money , Roinet ..... <br /> There is along
                  list of regional players also who are using and promoting
                  Mentation printers at their own level.
                </div>
              </div> */}
          </div>
        </Container>

        <div className="third-container  my-5">
          <div className="products-section">
            <h1 className="title mt-5">
              "Presenting Our Finest Explore{" "}
              <span>"Mentation Technologies"</span> Groundbreaking Fintech
              Products!"
            </h1>
            <Container>
              <Row className="g-4 card-gallery">
                {fintechData.map((product) => (
                  <Col xs={12} sm={12} md={6} lg={4} key={product.id}>
                    <Card className="card">
                      <div className="image-container">
                        <Card.Img
                          className="image"
                          variant="top"
                          src={product.productImage}
                        />
                      </div>
                      <Card.Body className="card-body">
                        <Card.Title className="card-title">
                          {product.productName}
                        </Card.Title>
                        <Card.Text className="card-text d-inline-block truncate-multiline">
                          {product.productDescription}
                        </Card.Text>
                      </Card.Body>
                      <div className="button">
                        <Link className="Link" to={product.baseUrl}>
                          Know More
                        </Link>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
