import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import Company from "./pages/About/Company/Company";
import Management from "./pages/About/Management/Management";
import Contact from "./pages/Support/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import Error from "./pages/ErrorPage/Error";
import Login from "./pages/Login-Pages/Login/Login";
import Register from "./pages/Login-Pages/Register/Register";
// Products Routes starts //
import Fintec from "./pages/Products/Printer-Soloution/Fintech/Fintech";
import Voter from "./pages/Products/Printer-Soloution/Voter/VoterManagement";
import Billing from "./pages/Products/Printer-Soloution/Bolling&POS/Billing";
import AIS140 from "./pages/Products/Tracking-soloutions/AIS140-VLTD/Ais140";
import FleetManagement from "./pages/Products/Tracking-soloutions/Fleet-Management/Fleet";
import Vehicle from "./pages/Products/Tracking-soloutions/GPS-Locate/Vehicle";
import IndentityManagement from "./pages/Products/Indentity-Soloutions/Facial/IndentityManagement";
import Attendence from "./pages/Products/Indentity-Soloutions/Attendence/Attendence";
import Biometric from "./pages/Products/Indentity-Soloutions/Biometric/Biometric";
import MT580DP from "./pages/product/Printer-Soloution/Fintech/Mt580dp/Mt580dp.jsx";
import MT580P from "./pages/product/Printer-Soloution/Fintech/Mt580p/MT580P.jsx";
import MT580Pmini from "./pages/product/Printer-Soloution/Voter/MT580Pmini/MT580Pmini.jsx";
import Printersp from "./pages/product/Printer-Soloution/Bills-Pos/800P/800P.jsx";
import Printersdp from "./pages/product/Printer-Soloution/Bills-Pos/800p2/800dp.jsx";
import PrintersP2 from "./pages/product/Printer-Soloution/Bills-Pos/P2/P2.jsx";
import Ais140 from "./pages/product/Tracking/AIS140/Ais140.jsx";
import Locate from "./pages/product/Tracking/Locate/Locate.jsx";
// Products Routes Ends //
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollTop from "./ScrollTop.js";
import Blog from "./pages/Support/Blog/Blog.jsx";
import Drivers from "./pages/Support/Drivers/Drivers.jsx";

// others pages routes
import PrivacyPolicy from "./pages/Others/Privacy/Privacy.jsx";
import Terms from "./pages/Others/Term&Conditions/Terms.jsx";

export default function App() {
  return (
    <Router>
      <Navbar />
      <ScrollTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/company" element={<Company />} />
        <Route path="/management" element={<Management />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/drivers" element={<Drivers />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="*" element={<Error />} />
        <Route path="/products/Fintech" element={<Fintec />} />
        <Route path="/products/Vehicle" element={<Vehicle />} />
        <Route path="/products/facial" element={<IndentityManagement />} />
        <Route path="/products/Voter" element={<Voter />} />
        <Route path="/products/Billing" element={<Billing />} />
        <Route path="/products/AIS140" element={<AIS140 />} />
        <Route path="/products/FleetManagement" element={<FleetManagement />} />
        <Route path="/products/Biometric" element={<Biometric />} />
        <Route path="/products/attendence" element={<Attendence />} />
        <Route path="/products/fintech/mt580dp" element={<MT580DP />} />
        <Route path="/products/fintech/mt580p" element={<MT580P />} />
        <Route path="/products/voter/MT580Pmini" element={<MT580Pmini />} />
        <Route path="/products/billingandpos/Mt800p" element={<Printersp />} />
        <Route path="/products/ais140/AIS140" element={<Ais140 />} />
        <Route path="/products/fleet/locate" element={<Locate />} />
        <Route
          path="/products/billingandpos/Mt800dp"
          element={<Printersdp />}
        />
        <Route path="/products/billingandpos/Mtp2" element={<PrintersP2 />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-condition" element={<Terms />} />
      </Routes>
      <Footer />
    </Router>
  );
}
