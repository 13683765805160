import React from "react";
import "./Blog.scss";
import { Helmet } from "react-helmet";
export default function Blog() {
  return (
    <>
      
      <Helmet>
        <title>Installation Guides | Mentation Technologies</title>
        <meta
          name="description"
          content="Explore our blog for comprehensive tutorials and videos on installing and using our hardware and software solutions, including thermal printers, GPS systems, fleet management, and more."
        />
        <meta
          name="keywords"
          content="installation tutorials, thermal printer setup, GPS installation, fleet management, software installation, Mentation Technologies blog"
        />
        <meta property="og:title" content="Installation Tutorials and Guides | Mentation Technologies" />
        <meta
          property="og:description"
          content="Explore our blog for comprehensive tutorials and videos on installing and using our hardware and software solutions, including thermal printers, GPS systems, fleet management, and more."
        />
        <meta property="og:image" content="https://mentationtech.in/static/media/Logo.a8779e5090cd14a6ca38.png" />
        <meta property="og:url" content="https://mentationtech.in/blog" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Installation Tutorials and Guides | Mentation Technologies" />
        <meta
          name="twitter:description"
          content="Explore our blog for comprehensive tutorials and videos on installing and using our hardware and software solutions, including thermal printers, GPS systems, fleet management, and more."
        />
        <meta name="twitter:image" content="https://mentationtech.in/static/media/Logo.a8779e5090cd14a6ca38.png" />
        <link rel="canonical" href="https://mentationtech.in/blog" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Installation Tutorials and Guides",
            "description": "Explore our blog for comprehensive tutorials and videos on installing and using our hardware and software solutions, including thermal printers, GPS systems, fleet management, and more.",
            "url": "https://mentationtech.in/blog"
          }
          `}
        </script>
      </Helmet>

      <div className="blog-container">
        <div className="container">
          <div className="row mt-5 text-center">
            <h1>Experience Our Products Installation Stories & Videos</h1>
            {/* 1 */}
            <div
              className="col-md-6 col-lg-4 mt-5 mb-5 wow fadeInUp"
              data-wow-delay=".2s"
              style={{
                visibility: "visible",
                animationDelay: "0.2s",
                animationName: "fadeInUp",
              }}
            >
              <div className="blog-grid">
                <div className="blog-grid-img position-relative">
                  <iframe
                    width="100%"
                    height="300"
                    src="https://www.youtube.com/embed/Zy-mYCUEHbU?si=2R6Fr3BfdkdVlPPM"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="blog-grid-text p-4">
                  <p className="display-30">
                    How to use Thermal Printer to print from Laptop using
                    Bluetooth Interface
                  </p>
                </div>
              </div>
            </div>
            {/* 2 */}
            <div
              className="col-md-6 col-lg-4 mt-5 mb-5 wow fadeInUp"
              data-wow-delay=".2s"
              style={{
                visibility: "visible",
                animationDelay: "0.2s",
                animationName: "fadeInUp",
              }}
            >
              <div className="blog-grid">
                <div className="blog-grid-img position-relative">
                  <iframe
                    width="100%"
                    height="300"
                    src="https://www.youtube.com/embed/MvDS1K_CSko?si=Nkd8yUCX-eDzPxUl"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="blog-grid-text p-4">
                  <p className="display-30">
                    Internet Explorer Print Page Setup for Thermal Printer
                  </p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}
