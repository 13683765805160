import React, { useState } from "react";
import "../../../styles/Product.scss";
import { Helmet } from "react-helmet";
import mt800dp1 from "../../../../../assests/product/Billing&POS/MT800DP-1.png";
import mt800dp2 from "../../../../../assests/product/Billing&POS/MT800DP-2.png";
import mt800dp3 from "../../../../../assests/product/Billing&POS/MT800DP-3.png";
import mt800dp4 from "../../../../../assests/product/Billing&POS/MT800DP-4.png";

// MT800DP

import mt580Dpdatasheet from "../../../../../assests/product/Billing&POS/Datasheet/MT800DP-DataSheet.pdf";
import { Link } from "react-router-dom";
export default function P800dp() {
  // Initialize state for the main image URL
  const [mainImage, setMainImage] = useState(mt800dp4);

  // Array of image imports
  const images = [mt800dp4, mt800dp2, mt800dp1, mt800dp3];

  // Handle thumbnail click
  const handleThumbnailClick = (img) => {
    setMainImage(img);
  };
  return (
    <>
      <Helmet>
        <title>
          MT800DP 80mm Line Thermal Printer | Stationary Use Thermal Printer
        </title>
        <meta
          name="description"
          content="The MT800DP is an 80mm line thermal printer designed for stationary use, featuring WiFi connectivity. Ideal for grocery stores, mid-sized shops, and ticketing service providers."
        />
        <meta
          name="keywords"
          content="80mm line thermal printer, MT800DP, stationary thermal printer, WiFi thermal printer, thermal printer for shops, thermal printer for ticketing"
        />
        <meta property="og:title" content="MT800DP 80mm Line Thermal Printer" />
        <meta
          property="og:description"
          content="Discover the MT800DP, an 80mm line thermal printer designed for stationary use with WiFi connectivity. Perfect for grocery stores, shops, and ticketing services."
        />
        <meta property="og:image" content="https://mentationtech.in/static/media/MT800DP-4.ae8b4d36db85acbe5d0d.png" />
        <meta property="og:url" content="https://mentationtech.in/products/billingandpos/Mt800p" />
      </Helmet>

      <div>
        <div className="single-product">
          <section className="py-5">
            <div className="container">
              <div className="row gx-5">
                <aside className="col-lg-6 aside">
                  <div className="image-container">
                    <img
                      className="rounded-4 fit images"
                      src={mainImage}
                      alt="Main view"
                    />
                  </div>
                  <div className="d-flex justify-content-center mb-3">
                    {images.map((img, index) => (
                      <Link
                        key={index}
                        className="border mx-1 rounded-2 item-thumb"
                        onClick={() => handleThumbnailClick(img)}
                        to="#"
                      >
                        <img
                          width="60"
                          height="60"
                          className="rounded-2 thumbnail"
                          src={img}
                          alt={`Thumbnail ${index + 1}`}
                        />
                      </Link>
                    ))}
                  </div>
                </aside>
                <main className="col-lg-6 main">
                  <div className="ps-lg-3">
                    <h4 className="title text-dark">
                      80mm line thermal printer designed for stationary use.
                    </h4>
                    <div className="d-flex flex-row my-3 product-name">
                      <p>MT800DP</p>
                    </div>

                    {/* <div className="mb-3 product-price">
                    <span className="h5">&#8377;6000</span>
                    <span className="text">/per printer</span>
                  </div> */}

                    <div className="description">
                      <p>
                        The MT800Dp is an 80mm line thermal printer designed for
                        stationary use. With no battery backup, it’s ideal for
                        settings where mobility isn’t required. Featuring WiFi
                        connectivity, this printer is widely favored by grocery
                        stores, mid-sized shops, ticketing service providers,
                        and more
                      </p>
                    </div>

                    <div className="key-features">
                      <h1>Key features typically include</h1>
                      <div className="list">
                        <ul className="features">
                          <li>
                            <strong>Bluetooth connectivity:</strong> <br /> For
                            easy pairing with smartphones, tablets, and
                            computers.
                          </li>
                          <li>
                            <strong>High printing speed:</strong> <br />
                            Efficiently produces receipts, and other documents.
                          </li>
                          <li>
                            <strong>Printers:</strong> <br />
                            Operate on electricity for dependable and consistent
                            printing.
                          </li>
                          <li>
                            <strong>wifi Connectivity:</strong>
                            <br /> seamless connectivity for muliple users.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="row short-heighlight">
                      <p className="des">Get To know More about this Product</p>
                      <span>
                        <Link
                          to={mt580Dpdatasheet}
                          target="_blank"
                          download="MT800DP.pdf"
                          className="link"
                        >
                          Download PDF
                        </Link>
                      </span>
                    </div>
                    <hr />
                  </div>
                </main>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
