/* eslint-disable no-unused-vars */
import React from "react";
import "./Home.scss";
import { Helmet } from "react-helmet";
import Sliders from "../../Components/Showcase/Slider";
import { Container, Row, Col, Card } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import video from "../../assests/slider/video-1.mp4";
import MT580P from "../../assests/Home/MT580P-Photoroom.png";
import MTS140 from "../../assests/Home/MTS 140-Photoroom.png";
import MT800P2 from "../../assests/Home/MT800 P2-Photoroom.png";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>
      {/* Reat Helmet */}
      <Helmet>
        <title>Mentation Technologies | Home</title>
        <meta
          name="description"
          content="Mentation Technologies offers cutting-edge hardware and software solutions including thermal printers, GPS devices, fleet management systems, and more. Discover innovation and excellence in technology."
        />
        <meta
          name="keywords"
          content="hardware solutions, software solutions, thermal printers, GPS devices, fleet management, technology solutions"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Mentation Technologies | Leading Hardware & Software Solutions"
        />
        <meta
          property="og:description"
          content="Mentation Technologies provides advanced hardware and software solutions, including thermal printers, GPS tracking, fleet management, and more. Explore our innovative products and services."
        />
        <meta
          property="og:image"
          content="https://mentationtech.in/static/media/Logo.a8779e5090cd14a6ca38.png"
        />
        <meta property="og:url" content="https://mentationtech.in/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Mentation Technologies | Leading Hardware & Software Solutions"
        />
        <meta
          name="twitter:description"
          content="Discover Mentation Technologies, offering state-of-the-art hardware and software solutions like thermal printers, GPS devices, and fleet management systems."
        />
        <meta
          name="twitter:image"
          content="https://mentationtech.in/static/media/Logo.a8779e5090cd14a6ca38.png"
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Mentation Technologies",
              "url": "https://mentationtech.in/",
              "logo": "https://mentationtech.in/static/media/Logo.a8779e5090cd14a6ca38.png",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-998-779-1977",
                "contactType": "Customer Service",
                "areaServed": "Global",
                "availableLanguage": "English"
              },
            }
          `}
        </script>
      </Helmet>
      {/* React helmet  */}

      <Sliders />
      <Container className="homeContainer">
        <Row className="">
          <Col xs={12} md={12} lg={6}>
            <div className="content">
              <h2 className="home-heading">
                Welcome to <br />
                Mentation Technologies <br />
                Private Limited
              </h2>
              <p className="home-text mt-md-5">
                where innovation meets excellence, and every journey begins with
                a bold step towards a brighter future. Our commitment to pushing
                boundaries and fostering creativity ensures that together, we
                pave new paths, conquer challenges, and achieve remarkable
                milestones. Join us on this exciting adventure as we shape
                tomorrow's possibilities, one innovative solution at a time
              </p>
            </div>
          </Col>
          <Col xs={12} md={12} lg={6} className="mt-sm-5">
            <div className="swiper-container">
              <video src={video} autoPlay muted loop />
            </div>
          </Col>
        </Row>
      </Container>

      {/* container 2 */}
      <Container className="service-container">
        <Row>
          <Col>
            <h1 className="serviceHeading">
              Experience the Best in Hardware Technology with Our Cutting-Edge
              Products
            </h1>
            <p className="servicecontent">
              At Mentation Technology Private Limited, we pride ourselves on
              being at the forefront of technological innovation. Our mission is
              to deliver unparalleled hardware solutions that empower
              individuals and businesses to excel in a rapidly evolving digital
              landscape. From high-performance computers and servers to the
              latest in smart home technology and wearable devices, our product
              range is designed to meet the diverse needs of our customers. We
              source and develop only the finest components to ensure
              reliability, speed, and efficiency in every product we offer.
            </p>
            <p className="servicecontent">
              Our team of experts is dedicated to staying ahead of the curve,
              continuously researching and integrating the newest advancements
              in hardware technology. This commitment to excellence allows us to
              provide products that not only meet but exceed industry standards.
              Whether you're a tech enthusiast looking for the latest gadgets, a
              business seeking robust and scalable solutions, or a gamer in need
              of top-tier performance, we have something for everyone. Our
              customer-centric approach ensures that we understand your needs
              and deliver products that enhance your digital experience.
            </p>
            <p className="servicecontent">
              In addition to our superior product offerings, we provide
              exceptional customer service and support. Our knowledgeable and
              friendly staff are always ready to assist you, whether you're
              making a purchase or seeking technical advice. We believe in
              building long-lasting relationships with our customers by
              providing not just products, but comprehensive solutions that
              drive success. Discover the future of hardware technology with
              Mentation Technology Private Limited and experience the difference
              that cutting-edge innovation can make.
            </p>

            <h2 className="serviceHeading">
              Explore Our Diverse Range of Services and Products
            </h2>
            {/* First Marquee */}
            <Marquee autoFill pauseOnClick direction="left" speed={50} loop={0}>
              <div className="marquee">
                <p>Thermal Printers</p>
              </div>
              <div className="marquee">
                <p>Receipt Printers</p>
              </div>
              <div className="marquee">
                <p>Tracking Solutions</p>
              </div>
              <div className="marquee">
                <p> GPS Devices</p>
              </div>
              <div className="marquee">
                <p>VLTD Devices</p>
              </div>
            </Marquee>

            {/* Second Marquee */}
            <Marquee
              autoFill
              pauseOnClick
              direction="right"
              speed={50}
              loop={0}
            >
              <div className="marquee">
                <p>Fleet Management</p>
              </div>
              <div className="marquee">
                <p>AIS 140 Devices</p>
              </div>
              <div className="marquee">
                <p> Label Printers</p>
              </div>
              <div className="marquee">
                <p> Portable Printers</p>
              </div>
              <div className="marquee">
                <p>Fintech Solutions</p>
              </div>
            </Marquee>
          </Col>
        </Row>
      </Container>

      {/* Container 3 */}
      <Container className="my-5 card-container">
        <h2 className="text-center my-4 productsHeading">
          Most Selling Products
        </h2>
        <Row className="justify-content-center">
          <Col xs={12} sm={6} md={4} lg={4} xl={3} className="mb-4">
            <Card className="card">
              <div className="product-img-wrapper">
                <Link to="/products/fintech/mt580p">
                  <Card.Img variant="top" src={MT580P} />
                </Link>
              </div>
              <Card.Body className="card-body">
                <Link to="/products/fintech/mt580p" className="link">
                  <h4 className="producttitle">MT580P</h4>
                </Link>
                <Card.Text className="productdesc">
                  The Mentation MT580P is a portable Bluetooth Thermal Printer
                  with 58mm paper size.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} sm={6} md={4} lg={4} xl={3} className="mb-4">
            <Card className="card">
              <div className="product-img-wrapper">
                <Card.Img variant="top" src={MTS140} />
              </div>
              <Card.Body className="card-body">
                <Link to="/products/ais140/AIS140" className="link">
                  <h4 className="producttitle">MTS140</h4>
                </Link>
                <Card.Text className="productdesc">
                  The Mentation MTS140 is a cutting-edge GPS device designed for
                  advanced vehicle tracking
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} sm={12} md={4} lg={4} xl={3} className="mb-4">
            <Card className="card">
              <div className="product-img-wrapper">
                <Card.Img variant="top" src={MT800P2} />
              </div>
              <Card.Body className="card-body">
                <Link to="/products/billingandpos/Mtp2" className="link">
                  <h4 className="producttitle">MT800P2</h4>
                </Link>
                <Card.Text className="productdesc">
                  The Mentation MT800P2 is a portable Bluetooth label printer
                  compatible with 80mm paper.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
