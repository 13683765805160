import React, { useState } from "react";
import "../../../styles/Product.scss";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import MT580Pmini1 from "../../../../../assests/product/Voter/MT580mini-1.png";
import MT580Pmini2 from "../../../../../assests/product/Voter/MT580mini-3.png";
import MT580Pmini3 from "../../../../../assests/product/Voter/MT580mini-2.png";
import MT580PminiDatasheet from "../../../../../assests/product/Voter/Datasheet/MT580Pmini-Datasheet.jpeg";
export default function MT580Pmini() {
  // Initialize state for the main image URL
  const [mainImage, setMainImage] = useState(MT580Pmini1);

  // Array of image imports
  const images = [MT580Pmini1, MT580Pmini2, MT580Pmini3];

  // Handle thumbnail click
  const handleThumbnailClick = (img) => {
    setMainImage(img);
  };
  return (
    <>
      <Helmet>
        <title>
          Mentation MT580mini Portable Bluetooth Thermal Printer | 58mm Compact
          Printer
        </title>
        <meta
          name="description"
          content="The Mentation MT580mini is a compact, portable Bluetooth thermal printer in the 58mm category, designed for cost-effective solutions with support for multiple Indian languages."
        />
        <meta
          name="keywords"
          content="Mentation MT580mini, portable Bluetooth thermal printer, 58mm thermal printer, compact printer, rechargeable battery printer"
        />
        <meta
          property="og:title"
          content="Mentation MT580mini Portable Bluetooth Thermal Printer"
        />
        <meta
          property="og:description"
          content="Explore the Mentation MT580mini, a compact and portable Bluetooth thermal printer with support for multiple Indian languages. Ideal for cost-effective solutions in the 2-inch paper width category."
        />
        <meta property="og:image" url="https://mentationtech.in/static/media/MT580mini-1.a90cc0c7b7e6cbd14a1c.png" />
        <meta property="og:url" content="https://mentationtech.in/products/voter/MT580Pmini" />
      </Helmet>
      <div>
        <div className="single-product">
          <section className="py-5">
            <div className="container">
              <div className="row gx-5">
                <aside className="col-lg-6 aside">
                  <div className="image-container">
                    <img
                      className="rounded-4 fit images"
                      src={mainImage}
                      alt="Main view"
                    />
                  </div>
                  <div className="d-flex justify-content-center mb-3">
                    {images.map((img, index) => (
                      <Link
                        key={index}
                        className="border mx-1 rounded-2 item-thumb"
                        onClick={() => handleThumbnailClick(img)}
                        to="#"
                      >
                        <img
                          width="60"
                          height="60"
                          className="rounded-2 thumbnail"
                          src={img}
                          alt={`Thumbnail ${index + 1}`}
                        />
                      </Link>
                    ))}
                  </div>
                </aside>
                <main className="col-lg-6 main">
                  <div className="ps-lg-3">
                    <h4 className="title text-dark">
                      Mentation portable Bluetooth Thermal Printer in 58mm
                      category
                    </h4>
                    <div className="d-flex flex-row my-3 product-name">
                      <p>Mt580mini</p>
                    </div>

                    <div className="description">
                      <p>
                        Mentation Mt580mini is portable Bluetooth Thermal
                        Printer in 58mm category only. It supports multiple
                        Indian languages and gives variety to people who are
                        looking for cost effective solution in 2 inch paper
                        width category
                      </p>
                    </div>

                    <div className="key-features">
                      <h1>Key features typically include</h1>
                      <div className="list">
                        <ul className="features">
                          <li>
                            <strong>Bluetooth connectivity:</strong> <br /> For
                            easy pairing with smartphones, tablets, and
                            computers.
                          </li>
                          <li>
                            <strong>High printing speed:</strong> <br />
                            Efficiently produces receipts, labels, and other
                            documents.
                          </li>
                          <li>
                            <strong>Rechargeable battery:</strong> <br />
                            Provides extended usage without needing constant
                            power supply.
                          </li>
                          <li>
                            <strong>Compact and lightweight design:</strong>
                            <br /> Even more compact and lightweight than its
                            inhouse competition MT580P.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="row short-heighlight">
                      <p className="des">Get To know More about this Product</p>
                      <span className="mt-3">
                        <Link
                          to={MT580PminiDatasheet}
                          target="_blank"
                          download="Mt580mini.pdf"
                          className="link"
                        >
                          Download PDF
                        </Link>
                      </span>
                    </div>
                    <hr />
                  </div>
                </main>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
